body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	background:#AAA;
}
#wrapper{
	padding:0px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
nav{
	background:#CCC;
	position:fixed;
	height:64px;
	top:0;
	left:0;
	transform:translateY(-100%);
	transition: transform .35s ease-in-out;
	z-index:1001;
	.enter-done &{
		transform:translateY(0%);
	}
	li{
		display:inline-block;
		padding:20px;
		a{
			color:red;
			text-decoration: none;
			&:hover{
				color:orange;
			}
		}
	}
}

.shadow{
	font-family: house-movements-sign, sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size:80px;
	color:#FCE200;
   position: relative;
   top: -3px;
   left: -3px;
   text-shadow: 1px 1px #000,
                2px 2px #000,
                3px 3px #000,
                4px 4px #000,
                5px 5px #000,
                6px 6px #000,
                7px 7px #000,
                8px 8px #000;
}

.page{
	position:fixed;
	transition: all .35s ease-in-out;
	&.appear-done{
		opacity:1;
	}
	background:#AAA;
}
.page-enter {
  transform: translate(-100%, 0);
	z-index:1000;
}
.page-enter-active {
  transform: translate(0, 0);
}
.page-exit {
  transform: translate(100%, 0);
}
